import { Component, OnInit } from '@angular/core';

import { CompReport, CompReportService } from 'core/comp-report';
import { Observable } from 'rxjs';
import { UserEventTypes } from 'core/user-events/user-event-types.model';
import { IUserEventCallback } from 'core/user-events/user-event.directive';
import { UserEvent } from 'core/user-events/user-event.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss']
})
export class ThankYouPageComponent implements OnInit, IUserEventCallback {
  complimentaryReports$: Observable<CompReport[]>;
  thankYouHeading: string;
  thankYouMessageHtml: string;
  userEventTypes: typeof UserEventTypes = UserEventTypes;
  websiteGuid: string;

  constructor(private compReportService: CompReportService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.websiteGuid = params.guid;
    });

    this.complimentaryReports$ = this.compReportService.compReports$;

    // Heading and Message are in the component-side for future binding to allow the text/html to be pulled from a CMS (wordpress?)
    this.thankYouHeading = 'Thank You';
    // took out the Message html text from here so that we culd add the user events to the links
  }

  populateUserEvent(userEvent: UserEvent): boolean {
    userEvent.websiteGuid = this.websiteGuid;
    return true;
  }
}
