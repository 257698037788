export enum UserEventTypes {
  Navigation_Thank_You_Page = 1,
  Thank_You_View_Report = 2,
  Thank_You_NICMAP_Webpage_Navigation = 3,
  Thank_You_NIC_Webpage_Navigation = 4,
  Thank_You_QA_Email = 5,
  Navigation_Report_Page = 6,
  Report_PDF = 7,
  Report_Back = 8,
  Report_QA_Email = 9
}

// the number index is the same as the UserEventTypes enum above.
export const NavigationUserEvents = {
  1: {
    id: UserEventTypes.Navigation_Thank_You_Page,
    message: 'Navigated to the Thank You page'
  },
  6: {
    id: UserEventTypes.Navigation_Report_Page,
    message: 'Navigated to the Report page'
  }
};

// the number index is the same as the UserEventTypes enum above.
export const ClickUserEvents = {
  2:  {
    id: UserEventTypes.Thank_You_View_Report,
    message: 'Clicked View Report for PID ${propertyId} on the Thank You page'
  },
  3:  {
    id: UserEventTypes.Thank_You_NICMAP_Webpage_Navigation,
    message: 'Clicked NICMAP website link for PID ${propertyId} on the Thank You page'
  },
  4:  {
    id: UserEventTypes.Thank_You_NIC_Webpage_Navigation,
    message: 'Clicked NIC website link for PID ${propertyId} on the Thank You page'
  },
  5:  {
    id: UserEventTypes.Thank_You_QA_Email,
    message: 'Clicked QA Email link for PID ${propertyId} on the Thank You page'
  },
  7:  {
    id: UserEventTypes.Report_PDF,
    message: 'Clicked PDF button for PID ${propertyId} on the Report page'
  },
  8:  {
    id: UserEventTypes.Report_Back,
    message: 'Clicked Back button for PID ${propertyId} on the Report page'
  },
  9:  {
    id: UserEventTypes.Report_QA_Email,
    message: 'Clicked QA Email link for PID ${propertyId} on the Report page'
  }

};
