import { Injectable } from '@angular/core';
import { Observable,  BehaviorSubject, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ApiService } from 'core/api.service';
import { CompReport } from './comp-report.model';
import { PropertyInfo } from './property-info.model';
import { CompSetInfo } from './comp-set-info.model';
import { NearByPropertyInfo } from './nearby-property-info.model';
import { UrlToken } from 'core/url-token.model';

@Injectable({
  providedIn: 'root'
})
export class CompReportService {
  private _compReports = new BehaviorSubject<CompReport[]>([]);
  readonly compReports$: Observable<CompReport[]> = this._compReports.asObservable();
  compReportsGuid: string;

  constructor(private apiService: ApiService) { }

  get compReports(): CompReport[] {
    return this._compReports.getValue();
  }

  updateCompReports(guid: string): Observable<CompReport[]> {
    this.compReportsGuid = guid;
    return this.apiService.get<CompReport[]>(`CompReport/${guid}`, 1, true)
      .pipe(tap(res => this._compReports.next(res)));
  }

  propertyInfo(guid: string, reportTypeId: number, propertyId: number): Observable<PropertyInfo> {
    return this.apiService.get<PropertyInfo>(`CompReport/${guid}/${reportTypeId}/${propertyId}/propertyInfo`, 1, true)
    .pipe(tap((data: PropertyInfo) => {
      return data;
    }),  catchError((err) => {
      throw err;
    }));
  }

  compSetProperties(guid: string, reportTypeId: number, propertyId: number): Observable<CompSetInfo> {
    return this.apiService.get<CompSetInfo>(`CompReport/${guid}/${reportTypeId}/${propertyId}/compsetproperties`, 1, true)
    .pipe(tap((data: CompSetInfo) => {
      return data;
    }),  catchError((err) => {
      throw err;
    }));
  }

  nearByProperties(guid: string, reportTypeId: number, propertyId: number): Observable<NearByPropertyInfo[]> {
    return this.apiService.get<NearByPropertyInfo[]>(`CompReport/${guid}/${reportTypeId}/${propertyId}/nearbyproperties`, 1, true)
    .pipe(tap((data: NearByPropertyInfo[]) => {
      return data;
    }),  catchError((err) => {
      throw err;
    }));
  }

  segmentData(guid: string, reportTypeId: number, propertyId: number): Observable<any> {
    return this.apiService.get<any>(`CompReport/${guid}/${reportTypeId}/${propertyId}/segmentdata`, 1, true)
    .pipe(tap((data: any) => {
      return data;
    }),  catchError((err) => {
      throw err;
    }));
  }

  getPDFlink(guid: string, reportTypeId: number, propertyId: number): Observable<UrlToken> {
    return this.apiService.get<UrlToken>(`CompReport/${guid}/${reportTypeId}/${propertyId}/PDF`, 1, true)
    .pipe(tap((data: UrlToken) => {
      return data;
    }),  catchError((err) => {
      throw err;
    }));
  }


}
