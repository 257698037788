import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loadingCount = 0;
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$: Observable<boolean> = this._isLoading.asObservable();

  constructor() { }

  get isLoading(): boolean {
    return this._isLoading.getValue();
  }

  register() {
    if (!this.isLoading) {
      this._isLoading.next(true);
    }
    this._loadingCount++;
  }

  unregister() {
    this._loadingCount--;
    if (this._loadingCount === 0) {
      this._isLoading.next(false);
    }
  }
}
