<div class="connect">
    <div class="heading">CONNECT WITH NIC</div>
    <div class="social">
        <a href="https://twitter.com/Natl_Inv_Ctr" target="_blank"><img src="assets/img/footer-twitter.png"> @Natl_Inv_Ctr</a>
        </div>
    <div class="social">
        <a href="https://www.linkedin.com/company/national-investment-center" target="_blank">
            <img src="assets/img/footer-linkedin.png"> National-investment-center
        </a>
    </div>
    <div class="social">
        <a href="https://www.instagram.com/ntl_invctr/" target="_blank">
            <img src="assets/img/footer-instagram.png">
            @NTL_INVCTR
        </a>
    </div>
    <div class="social">
        <a href="https://www.youtube.com/channel/UCSt7w2vc20tirZIJBJYNGwg" target="_blank">
            <img src="assets/img/footer-youtube.png"> NIC - National Investment Center
        </a>
    </div>
</div>
<p class="copy">
    &copy; {{copyrightYear}} National Investment Center for Seniors Housing &amp; Care, Inc. (NIC). All rights reserved. Data believed to be
    accurate but not guaranteed; subject to future revision. This report is a part of the NIC MAP® Data Service (NIC
    MAP). Distribution of this report or any part of this report without prior written consent or license by NIC is strictly
    prohibited. Subject to the NIC Terms of Use. For license information please contact NIC at 410-267-0504. Use of NIC MAP data is subject to the NIC MAP Data Attribution Requirements.
</p>
<div class="bottom-row">
    <!-- TODO: uncomment and bind for Terms of Use and Data Attribution
    <div><a>Terms of Use</a></div>
    <div class="vertical-divider">|</div> -->
    <div><a routerLink="/dataAttribution" target="_blank">Data Attribution Requirements</a></div>
    <div class="vertical-divider">|</div>
    <div>Phone: (410) 267-0504</div>
    <div class="vertical-divider">|</div>
    <div>Fax: (410) 268-4620</div>
    <div class="pull-right small italics ng-binding"></div>
</div>