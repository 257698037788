import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ThankYouPageComponent } from 'thank-you-page/thank-you-page.component';
import { CompReportSectionComponent } from 'thank-you-page/comp-report-section/comp-report-section.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { IntlModule } from '@progress/kendo-angular-intl';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { DataAttributionPageComponent } from './data-attribution-page/data-attribution-page.component';

@NgModule({
  declarations: [
    AppComponent,
    ThankYouPageComponent,
    CompReportSectionComponent,
    DataAttributionPageComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    GridModule,
    IntlModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
