import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataAttributionService {
  private requestUrl: string;

  constructor(
      @Inject(HttpClient) private http: HttpClient) {
      this.requestUrl = environment.dataAttributionUrl;
      if (!this.requestUrl.endsWith('/')){
        this.requestUrl = this.requestUrl.concat('/');
      }
  }

  getDataAttributionHeaderHtml(): Observable<string> {
    return this.http.get(this.requestUrl + 'dataAttributionHeader.html', { responseType: 'text' });
  }

  getDataAttributionTermsHtml(): Observable<string> {
      return this.http.get(this.requestUrl + 'dataAttributionTerms.html', { responseType: 'text' });
  }
}
