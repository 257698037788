export const ReportTypeDisplayNameLookup = {
  1: 'Seniors Housing Complimentary Report',
  // TODO: Currently the rest of these Report Types are using names from CR.lt_ReportType,
  //     BUT their actual Display Name may be different when we go to implement them.
  //     Update these strings if necessary!
  2: 'SNF National Report',
  3: 'SNF Contributor Report',
  4: 'SNF State Report',
  5: 'AR Complimentary Report',
};

export const ReportTypeRoutes = {
  seniorsHousing: 'seniors-housing',
  snfNational: 'snf-national',
  snfContributor: 'snf-contributor',
  snfState: 'snf-state',
  ar: 'ar',
  previewSeniorsHousing: 'preview-seniors-housing'
};

export const ReportTypeRouteLookup = {
  1: ReportTypeRoutes.seniorsHousing,
  2: ReportTypeRoutes.snfNational,
  3: ReportTypeRoutes.snfContributor,
  4: ReportTypeRoutes.snfState,
  5: ReportTypeRoutes.ar
};

export enum ReportTypes {
  seniorsHousing = 1,
  snfNational = 2,
  snfContributor = 3,
  snfState = 4,
  ar = 5,
  previewSeniorsHousing = 6 // preview of seniorsHousing (id 1) report
}
