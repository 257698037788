import { Directive, HostListener, OnInit, Input} from '@angular/core';
import { CompReport } from 'core/comp-report';
import { UserEventService } from './user-event.service';
import { UserEventTypes, ClickUserEvents } from './user-event-types.model';
import { UserEvent } from './user-event.model';

@Directive({
  selector: '[appUserEvent]'
})
export class UserEventDirective implements OnInit {

  @Input() compReport: CompReport;
  @Input() userEventId: UserEventTypes;
  @Input() userEventCallBack: IUserEventCallback;

  constructor(private readonly userEventService: UserEventService ) { }

  ngOnInit() { }

  @HostListener('click')
  clickEvent() {
    this.tryToLogEvent();
  }

  private tryToLogEvent(): void {
    if (this.userEventId > 0) {

      const ue: UserEvent = {
        userEventTypeId: this.userEventId,
        message: ClickUserEvents[this.userEventId].message,
        websiteGuid: null,
        reportTypeId: null,
        propertyId: null
      };

      if (this.userEventCallBack) {
        const shouldContinue = this.userEventCallBack.populateUserEvent(ue);
        if (shouldContinue) {
          this.userEventService.logUserEvent(ue);
        }
      }
      else {
          ue.websiteGuid = this.compReport?.websiteGuid;
          ue.reportTypeId = this.compReport?.reportTypeId;
          ue.propertyId = this.compReport?.propertyId;
          ue.message = this.userEventService.formatMessage(ue.message, this.compReport);
          this.userEventService.logUserEvent(ue);
      }
    }
  }
}

export interface IUserEventCallback {
  populateUserEvent(userEvent: UserEvent): boolean; // return false to cancel event
}
