import { Injectable } from '@angular/core';
import { UserEvent } from './user-event.model';
import { ApiService } from 'core/api.service';
import { CompReport } from 'core/comp-report';

@Injectable({
  providedIn: 'root'
})
export class UserEventService {

  constructor(private readonly apiService: ApiService) { }

  public logUserEvent(userEvent: UserEvent): void {
    this.apiService.post<UserEvent>('CompReport/LogUserEvent', userEvent, 0, false)
      .subscribe();
  }

  public formatMessage(source: string, compReport: CompReport): string {
    let result = source;
    // trying to replace variables in source string with properties from the compReport
    if (source && compReport) {
      for (const k in compReport) {
        if (compReport.hasOwnProperty(k)) {
          const token = `\${${k}}`;
          if (result.indexOf(token) >= 0) {
            result = result.replace(token, compReport[k]);
          }
        }
      }
    }
    return result;
  }
}
