import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LoadingService } from 'core/loading.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  isLoading$: Observable<boolean>;

  constructor(private readonly loaderService: LoadingService) { }

  ngOnInit(): void {
    this.isLoading$ = this.loaderService.isLoading$.pipe(delay(0));
  }
}
