import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

import { CompReport } from 'core/comp-report';
import { ReportTypeDisplayNameLookup, ReportTypeRouteLookup } from 'report/report-types.constants';
import { LoadingService } from 'core/loading.service';
import { formatDate } from '@angular/common';
import { UserEventTypes } from 'core/user-events/user-event-types.model';

@Component({
  selector: 'app-comp-report-section',
  templateUrl: './comp-report-section.component.html',
  styleUrls: ['./comp-report-section.component.scss']
})
export class CompReportSectionComponent implements OnInit, AfterViewInit {
  expirationNote: string;
  faFileInvoice = faFileInvoice;
  heading: string;
  message: string;
  @Input() report: CompReport;
  reportTypeRoute: string;
  viewReportBtnText: string;

  userEventTypes: typeof UserEventTypes = UserEventTypes;

  constructor(private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.register();
    this.expirationNote =  `Please Note your report is available until ${formatDate(new Date(this.report.reportEndDate), 'M/d/yyyy', 'en_US')}.`;
    this.heading = ReportTypeDisplayNameLookup[this.report.reportTypeId];
    this.message = this.report.isExpired
      ? `Your seniors housing complimentary report for <b>${this.report.propertyName}</b> has expired. To request extended access to this report, please send an email to <a href="mailto:qa@nic.org" target="_blank">QA@nic.org</a>.`
      : `Your seniors housing complimentary report for <b>${this.report.propertyName}</b> is ready for you to view. Click <b>View Report</b> to display your report.`;
    this.reportTypeRoute = ReportTypeRouteLookup[this.report.reportTypeId];
    this.viewReportBtnText = 'View Report';
  }

  ngAfterViewInit(): void {
    this.loadingService.unregister();
  }
}
