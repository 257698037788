import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BackToTopComponent, FooterComponent, HeaderComponent,
         JumpListLinkComponent, SpinnerComponent, ErrorComponent } from 'core/layout-components';
import { UserEventDirective } from './user-events/user-event.directive';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BackToTopComponent,
    JumpListLinkComponent,
    SpinnerComponent,
    ErrorComponent,
    UserEventDirective
  ],
  imports: [
    RouterModule,
    FontAwesomeModule,
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BackToTopComponent,
    JumpListLinkComponent,
    SpinnerComponent,
    ErrorComponent,
    UserEventDirective
  ],
  providers: []
})
export class CoreModule { }
