<div class="complimentary-report-section">
  <h2>{{heading}}</h2>
  <div class="card">
    <div class="row no-gutters">
      <div class="col-lg-10">
        <div class="card-body">
          <p class="card-text" [innerHtml]="message"></p>
          <p class="card-text" [innerHtml]="expirationNote"></p>
      </div>
      </div>
      <div class="col-lg-2 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-primary" [routerLink]="[reportTypeRoute, report.propertyId]"
          [disabled]="report.isExpired" appUserEvent [compReport]="report" [userEventId]="userEventTypes.Thank_You_View_Report">
          <fa-icon [icon]="faFileInvoice"></fa-icon>{{viewReportBtnText}}
        </button>
      </div>
    </div>
  </div>
</div>
