import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CompReportService } from './comp-report.service';

@Injectable({
  providedIn: 'root'
})
export class CompReportGuard implements CanActivate {
  constructor(
    private compReportService: CompReportService,
    private readonly router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const nextGuid = next.params.guid;
      if (nextGuid === this.compReportService.compReportsGuid &&
        this.compReportService.compReports.length > 0) {
          return true;
        }
      // else
      return new Observable(observer => {
        this.compReportService.updateCompReports(nextGuid)
          .subscribe(compReports => {
            if (compReports == null) {
              this.router.navigate(['/error']);
            }
            return observer.next(compReports.length > 0);
          }, error => {
            this.router.navigate(['/error']);
          });
    });
  }
}
