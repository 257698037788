import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompReportGuard } from 'core/comp-report';

import { ThankYouPageComponent } from 'thank-you-page/thank-you-page.component';
import { DataAttributionPageComponent } from 'data-attribution-page/data-attribution-page.component';
import { ErrorComponent } from 'core/layout-components/error/error.component';
import { UserEventTypes } from 'core/user-events/user-event-types.model';
import { UserEvent } from 'core/user-events/user-event.model';

const routes: Routes = [
  {
    path: 'dataAttribution',
    component: DataAttributionPageComponent,
    pathMatch: 'full'
  },
  {
    path: 'error',
    component: ErrorComponent,
    pathMatch: 'full'
  },
  {
    path: ':guid',
    component: ThankYouPageComponent,
    pathMatch: 'full',
    canActivate: [CompReportGuard],
    loadChildren: () => import('./report/report-routing.module').then(m => m.ReportRoutingModule),
    data: {
      userEvent: {
        userEventTypeId: UserEventTypes.Navigation_Thank_You_Page,
        websiteGuid: ':guid'
      } as UserEvent
    }
  },
  {
    path: ':guid',
    canActivate: [CompReportGuard],
    loadChildren: () => import('./report/report-routing.module').then(m => m.ReportRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
