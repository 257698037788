import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { LoadingService } from 'core/loading.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserEventService } from 'core/user-events/user-event.service';
import { UserEvent } from 'core/user-events/user-event.model';
import { combineLatest  } from 'rxjs';
import { NavigationUserEvents } from 'core/user-events/user-event-types.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'NIC MAP Complimentary Reports';

  constructor(
    private loadingService: LoadingService,
    private userEventService: UserEventService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        ),
      )
      .subscribe(event => {
        // If it's the start of navigation, show a loading indicator
        if (event instanceof NavigationStart) {
          this.loadingService.register();
          return;
        }

        // Else navigation has ended, so hide a loading indicator
        this.loadingService.unregister();
      });

    this.router.events
    .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }),
        mergeMap((route) => {
          return combineLatest([route.fragment, route.paramMap, route.data]);
        }))
    .subscribe(([fragment, paramMap, data]) => {
      if (!fragment && data && data.userEvent && data.userEvent.userEventTypeId) {
        const ue: UserEvent = {
          websiteGuid: data.userEvent?.websiteGuid,
          propertyId: data.userEvent?.propertyId,
          reportTypeId: data.userEvent?.reportTypeId,
          message: NavigationUserEvents[data.userEvent.userEventTypeId].message,
          userEventTypeId: data.userEvent.userEventTypeId,
        };

        // try to replace values with variables from the route params
        for (const k in ue) {
          if ( ue.hasOwnProperty(k)){
            const v = ue[k];

            if (typeof v === 'string' && v?.startsWith(':')){
              // this is a hack to figure out if we need to convert to number since
              // the azure post function will not work without the correct datatype.
              if (k.endsWith('Id')) {
                ue[k] = parseInt(paramMap.get(data.userEvent[k].substring(1)), 10);
              }
              else {
                ue[k] = paramMap.get(data.userEvent[k].substring(1));
              }
            }
          }
        }
        this.userEventService.logUserEvent(ue);
      }
    });
  }
}
