import { Component, Injectable, OnInit, Input } from '@angular/core';
import { DataAttributionService } from 'core/data-attribution.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-data-attribution-page',
  templateUrl: './data-attribution-page.component.html',
  styleUrls: ['./data-attribution-page.component.scss']
})
export class DataAttributionPageComponent implements OnInit {

  dataAttributionTermsHtml: string;
  dataAttributionHeaderHtml: string;

  constructor(private readonly dataAttributionService: DataAttributionService) { }

  ngOnInit(): void {
    this.dataAttributionService.getDataAttributionTermsHtml()
    .pipe(take(1))
    .subscribe(html => this.dataAttributionTermsHtml = html );

    this.dataAttributionService.getDataAttributionHeaderHtml()
    .pipe(take(1))
    .subscribe(html =>  this.dataAttributionHeaderHtml = html );
  }
}
