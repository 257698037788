<div class="container">
  <h1>{{thankYouHeading}}</h1>
  <div class="thank-you-message">
    <p>On behalf of the entire team at NIC MAP, we would like to thank you for contributing your data.</p>
    <p>As you may know, the National Investment Center for Seniors Housing & Care (NIC) is a nonprofit 501(c)3
      organization enabling access and choice for America’s elders
      by providing data, analytics, and connections that bring together investors and providers. NIC’s associated data
      service, NIC MAP, is the premier provider of
      comprehensive market data for the seniors housing and care sector, supplying data from more than 15,000 properties
      within 140 U.S. metro markets. Established in 2004,
      NIC MAP creates transparency for seniors housing and care by offering accurate, unbiased, and actionable
      market-level data on the entire sector’s property types and care segments.
      NIC MAP data is updated quarterly, monthly, and weekly, and includes property-level inventory by unit type, sales
      transactions, construction activity, aggregated occupancy and rent comps,
      demographics, and much more. NIC MAP is available through a variety of subscriptions and reports that can be
      customized based on your geographical needs. For more information about NIC MAP,
      please visit us at <a
        href="https://www.nic.org/nic-map/nic-map-products/professional-senior-housing-investment-data/" target="_blank"
        appUserEvent [userEventId]="userEventTypes.Thank_You_NICMAP_Webpage_Navigation" [userEventCallBack]="this">
        https://www.nic.org/nic-map/nic-map-products/professional-senior-housing-investment-data/</a>.</p>
    <p>As a “thank you” for your participation in our data collection efforts, we are providing you with this
      complimentary report, available only to our data contributors.
      In addition to information about your property, the report includes trended care segment occupancy and average
      rent data,
      and a competitive analysis of your property as compared to local competitors, selected based on nearby properties
      with the same services (care segments) available at your property.
      We hope you will find it useful as both a benchmarking and planning tool. Should you identify any errors in your
      data, please report them to
      <a href="mailto:qa@nic.org?subject=Data Issues with Seniors Housing Complimentary Report" target="_blank"
        appUserEvent [userEventId]="userEventTypes.Thank_You_QA_Email" [userEventCallBack]="this">QA@nic.org</a>.</p>
    <p>For more information about NIC, please visit <a href="https://www.nic.org" target="_blank" appUserEvent
        [userEventId]="userEventTypes.Thank_You_NIC_Webpage_Navigation"
        [userEventCallBack]="this">https://www.nic.org</a>.</p>
  </div>
  <app-comp-report-section *ngFor="let report of complimentaryReports$ | async" [report]="report">
  </app-comp-report-section>
</div>