import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Api GET request for given resource path
   * @param apiPath api path endpoint to request
   * @param retryCount retry count when request fails, default is 0
   * @param throwErr bool to indicate error should be thrown up to the caller
   */
  get<T>(path: string, retryCount: number = 0, throwErr?: boolean) {
    const apiUri = `${environment.apiUrl}${path}`;
    return this.httpClient.get<T>(apiUri, { headers: this.getFunctionsKeyHeader() }).pipe(
        retry(retryCount),
        catchError(this.handleError('get', [], throwErr))
      );
  }

  post<T>(path: string, data: T, retryCount: number = 0, throwErr?: boolean){
    const apiUri = `${environment.apiUrl}${path}`;
    return this.httpClient.post<T>(apiUri, data, { headers: this.getFunctionsKeyHeader() }).pipe(
        retry(retryCount),
        catchError(this.handleError('post', [], throwErr))
      );
  }

  /**
   * Gets the appropriate headers for api requests to the functions endpoint
   */
  private getFunctionsKeyHeader() {
    return {'x-functions-key': environment.apiKey || ''};
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T, throwErr?: boolean) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(`${operation} FAILED: `, error); // log to console instead

      // Let the app keep running by returning an empty result.
      if (throwErr) {
        return throwError(JSON.stringify(error));
      }

      result = (error.error === undefined) ? result : error.error;
      return of(result as T);
    };
  }
}
